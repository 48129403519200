// 导航页面

import React, { } from 'react';
import { removeLoginToken, getLoginToken, specialUsers } from './Utils';

function Main() {

    const token = getLoginToken();
    if (!token || !token.data) {
        window.location.reload();
        return <></>;
    }

    var $ = window.$;
    const protocl = window.location.protocol;

    var username = token.username;
    var accessToken = token.data;

    const go2ai = (webUrl, token) => {
        $("#go2ai").attr("action", webUrl);
        $("#shareId").val(token);
        $("#go2ai").submit();
    }

    const go2SaasApp = (appUrl) => {
        var token = getLoginToken();
        if (!token) {
            window.location.reload();
            return;
        }
        // let url = appUrl + "?access_token=" + token;
        $("#go2app").attr("action", appUrl);
        $("#access_token").val(accessToken);
        $("#go2app").submit();
    }

    const go2SaasApp2 = (appUrl, token, suffix) => {
        var _token = getLoginToken();
        if (!_token) {
            window.location.reload();
            return;
        }
        let url = appUrl + "?access_token=" + token + suffix;
        window.open(url);
    }

    const go2NotLoginApp = (appUrl) => {
        window.open(appUrl);
    }

    return (
        <>
            <div className="header">
                <img alt='cloud-header' style={{ width: 200 }} src="assets/img/header.png">
                </img>
                <button style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    border: 'none',
                    padding: '6px 10px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'inline-block',
                    fontSize: '12px',
                    margin: '4px 2px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    width: 90,
                }} onClick={() => {
                    removeLoginToken();
                    window.location.reload();
                }}>退出登录</button>
            </div>
            <div className="main">
                <div className="saasitem laws" onClick={() => {
                    go2SaasApp2("http://demo10.yonyouaud.com/ais/portal.html", "APIKEYUFoXH0wtakapwqwU", "#//ais/page.html#/biz/6F8491C1-F2C5-42F2-81F5-F052DBD13E44/A8194E15-1A54-4738-89D9-6266E120E0BB/FFE7A118-F1D2-4D2E-A1CA-497D783F71D2");
                }}>
                    <img alt='law' src="assets/img/law.png">
                    </img>
                    {/* <div className="saasitemtext" >法律法规<span className="card_product_type">APP/端</span></div> */}
                    <div className="saasitemtext" >法律法规</div>
                </div>
                <div className="saasitem case" onClick={() => {
                    go2SaasApp2("http://demo10.yonyouaud.com/ais/portal.html", "APIKEYUFoXH0wtakapwqwU", "#//ais/page.html#/biz/0C86DA18-29E1-4CA8-9211-4EAF08DF7646/DCC55843-516D-4CAD-97E7-FA3F97BF7A10");
                }}>
                    <img alt='case' src="assets/img/auditcase.png">
                    </img>
                    {/* <div className="saasitemtext">审计案例<span className="card_product_type">APP/端</span></div> */}
                    <div className="saasitemtext">审计案例</div>
                </div>
                {(!specialUsers.includes(username) ? <>
                    <div className="saasitem ent" onClick={() => {
                        go2SaasApp(protocl + "//saas.yonyouaud.com/cloud-service/enterprise/home/no-layout");
                    }}>
                        <img alt='bizz' src="assets/img/bizz.png">
                        </img>
                        {/* <div className="saasitemtext">企业画像<span className="card_product_type">端</span></div> */}
                        <div className="saasitemtext">企业画像</div>
                    </div>
                    <div className="saasitem ai" onClick={() => {
                        go2ai("https://demo10.yonyouaud.com/chat/share", "g36l7nkah22n74dto1k5vqps");
                    }}>
                        <img alt='airobot' src="assets/img/airobot.png">
                        </img>
                        {/* <div className="saasitemtext">审友GPT<span className="card_product_type">APP/端</span></div> */}
                        <div className="saasitemtext">审友GPT</div>
                    </div>
                    <div className="saasitem ocr" onClick={() => {
                        go2SaasApp(protocl + "//saas.yonyouaud.com/cloud-service/ocr/no-layout");
                    }}>
                        <img alt='ocr' src="assets/img/ocr.png">
                        </img>
                        {/* <div className="saasitemtext">文字识别OCR<span className="card_product_type">端</span></div> */}
                        <div className="saasitemtext">文字识别OCR</div>
                    </div>
                    <div className="saasitem asr" onClick={() => {
                        go2SaasApp(protocl + "//saas.yonyouaud.com/cloud-service/asr/no-layout");
                    }}>
                        <img alt='asr' src="assets/img/asr.png">
                        </img>
                        {/* <div className="saasitemtext">语音识别ASR<span className="card_product_type">端</span></div> */}
                        <div className="saasitemtext">语音识别ASR</div>
                    </div>
                    <div className="saasitem textdiff" onClick={() => {
                        go2SaasApp(protocl + "//saas.yonyouaud.com/cloud-service/text-compare/no-layout");
                    }}>
                        <img alt='diff' src="assets/img/textdiff.png">
                        </img>
                        {/* <div className="saasitemtext">文本比对<span className="card_product_type">端</span></div> */}
                        <div className="saasitemtext">文本比对</div>
                    </div>
                    <div className="saasitem blockchain" onClick={() => {
                        go2NotLoginApp("https://ec.bimart.cn/#/yysj");
                    }}>
                        <img alt='blockchain' src="assets/img/blockchain.png">
                        </img>
                        {/* <div className="saasitemtext">区块链函证<span className="card_product_type">端</span></div> */}
                        <div className="saasitemtext">区块链函证</div>
                    </div>
                </> : <></>)}
            </div>
            <div className="layer_qr">
                <img alt='mqr' src="assets/img/mqr.jpeg" />
            </div>
            <form id="go2app" target="_blank" src="#">
                <input type="hidden" name="access_token" id="access_token" />
            </form>
            <form id="go2ai" target="_blank" src="#">
                <input type="hidden" name="shareId" id="shareId" />
            </form>
            <div className="footer">
                Copyright&copy;2024 北京审友数治科技有限公司
            </div>
        </>
    );
}

export default Main;