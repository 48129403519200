import React, { useState, useRef } from 'react';
import { DEFAULT_TTL, setLoginToken } from './Utils';

function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const passwordRef = useRef();

    const doLogin = () => {
        var protocl = window.location.protocol;
        if (username === "" || password === "") {
            window.layer.msg("请输入用户名和密码");
            return;
        }
        window.$.ajax({
            type: 'POST',
            url: protocl + '//saas.yonyouaud.com/api/client-info/generateAccessToken',
            contentType: 'application/json;charset=utf8',
            data: JSON.stringify({ "clientId": username, "username": username, "clientSecret": password, "ttl": DEFAULT_TTL }),
            success: function (data) {
                var code = data.errorCode;
                if (code && code === "success") {
                    window.layer.msg("登录成功");
                    data.username = username;
                    setLoginToken(data);
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                } else {
                    window.layer.msg(data.errorMessage);
                }
            }
        })
    }

    return <>
        <img alt="main-background-img" src="./assets/img/main-logo.png" className="video-target" width="2348.4444444444443" height="1321" />
        <div className="login-6-left">
            <div className="login-6-left-container">
                <div className="login-6-left-img">
                    <div id="large-title">技术巨擎，审计引领者</div><span id="second-title">要坚持科技强审, 加强审计信息化建设</span>
                </div>
            </div>
        </div>
        <div className="login-6-right">
            <div className="login-top">
                <div className="login-6-left-header">
                    <div className="login-6-logo">
                    </div>
                </div>
            </div>
            <div className="login-6-right-container">
                <div className="login-6-login-form">
                    <form className="layui-form login-bottom">
                        <div className="center">
                            <div className="loginTitleParent">
                                <div id="loginTitle">审友云</div>
                            </div>
                            <div className="item-label">
                                <span className="label" id="accountLabel">账号/邮箱</span>
                            </div>
                            <div className="item">
                                <span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.00016 6.66732C9.47292 6.66732 10.6668 5.47341 10.6668 4.00065C10.6668 2.52789 9.47292 1.33398 8.00016 1.33398C6.5274 1.33398 5.3335 2.52789 5.3335 4.00065C5.3335 5.47341 6.5274 6.66732 8.00016 6.66732Z"
                                            stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14 14.666C14 11.3523 11.3137 8.66602 8 8.66602C4.6863 8.66602 2 11.3523 2 14.666"
                                            stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <input type="text" name="username" lay-verify="required" maxLength="24" autoComplete="off" id="username"
                                    className="layui-input" style={{ paddingLeft: 40 }} value={username} onChange={(e) => setUsername(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            if (passwordRef.current) {
                                                passwordRef.current.focus();
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div className="item-label">
                                <span className="label" id="passwordLabel">密码</span>
                            </div>
                            <div className="item">
                                <span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.3333 7.33398H2.66667C2.29848 7.33398 2 7.63246 2 8.00065V14.0007C2 14.3688 2.29848 14.6673 2.66667 14.6673H13.3333C13.7015 14.6673 14 14.3688 14 14.0007V8.00065C14 7.63246 13.7015 7.33398 13.3333 7.33398Z"
                                            stroke="#999999" strokeLinejoin="round" />
                                        <path
                                            d="M4.6665 7.33398V4.66732C4.6665 2.82637 6.1589 1.33398 7.99984 1.33398C9.84077 1.33398 11.3332 2.82637 11.3332 4.66732V7.33398"
                                            stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8 10V12" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <input type="password" name="password" lay-verify="required" autoComplete="off" maxLength="20"
                                    id="password" ref={passwordRef} className="layui-input" style={{ paddingLeft: 40 }} value={password} onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            doLogin();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="tip">
                            <div id="loginerror-tip" style={{
                                textAlign: 'center',
                                fontSize: 14,
                                color: 'red',
                                fontWeight: 'bold',
                                border: '1px solid red',
                                padding: '8px 5px',
                                display: 'none',
                            }}></div>
                        </div>
                        <div className="layui-form-item">
                            <button type="button" className="login-btn" lay-submit="" lay-filter="login" id="loginBtn"
                                title="login" onClick={doLogin}>登&nbsp;&nbsp;&nbsp;&nbsp;录</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="main-footer">
                <span id="loginFooter">Copyright © 2024 <a rel="noreferrer" href="https://www.yonyouaud.com" target="_blank">北京审友数治科技有限公司</a> 版权所有.</span>
            </div>
        </div>
    </>
}

export default Login;