import Login from './Login';
import Main from './Main';
import { getLoginToken } from './Utils';

function App() {
  var token = getLoginToken();
  if (token && token.data) {
    return <div className='Main'>
      <Main />
    </div>
  } else {
    return (
      <div className='App'>
        <Login />
      </div>
    );
  }
}

export default App;
