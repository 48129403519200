// 公共函数

export const DEFAULT_TTL = 7200;
const TOKEN_KEY = '__audit_cloud_user_token';

export const specialUsers = ['gtzh']

/**
data: "d557d98d-a516-411a-ae09-990c894d3ea1"
errorCode: "success"
errorMessage:"success"
host:"123.114.208.130"
showType:4
success:true
traceId:"b861acd9-3bce-4b4d-bc31-be185f9deac6"
*/

// 简单的加密函数
const encode = (str) => {
    return str.split('').map((c) => {
        return String.fromCharCode(c.charCodeAt(0) + 1);
    }).join('');
};

// 简单的解密函数
const decode = (str) => {
    return str.split('').map((c) => {
        return String.fromCharCode(c.charCodeAt(0) - 1);
    }).join('');
};

function setItemWithExpiry(key, value, ttl) {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item));
}

function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}

export const setLoginToken = (token) => {
    // 将token 进行简单的加密后存入localStorage
    var tokenStr = encode(JSON.stringify(token));
    setItemWithExpiry(TOKEN_KEY, tokenStr, 1000 * 60 * 60);
};

export const getLoginToken = () => {
    // 从localStorage中取出token并解密
    var tokenStr = getItemWithExpiry(TOKEN_KEY);
    return tokenStr ? JSON.parse(decode(tokenStr)) : null;
};

export const removeLoginToken = () => {
    localStorage.removeItem(TOKEN_KEY);
};